
  import {
    Container,
    Row,
    Col,
    Card,
    CardImg
  } from 'reactstrap';
  import 'bootstrap/dist/css/bootstrap.min.css';
  import { nanoid } from 'nanoid';
  import Slider from "react-slick";
  import CardModal from "./cardmodal.js";
  import { photo } from './datafile.js';



  function ShowPhotos() {

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 4.2,
        slidesToScroll: 2,
        autoplay: true,
        speed: 3500,
        autoplaySpeed: 0,
        cssEase: "linear",
        responsive: [
                
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
              breakpoint: 500,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
        ],
      };


    return (
        
    <Col>
      <Container fluid>
        <Row className="pb-5">
          <Col>
            <Container fluid>
              <Row>
                <Col>
                  <Slider {...settings}>

                    {photo.map((item,index)=>(
                      <Col key={nanoid()} className="py-2 px-2">
                          <Card inverse style={{
                                  aspectRatio: 1/1,
                                  maxWidth: "100%"
                              }}>
                              <CardImg
                              alt="Card img"
                              src={item.img}
                              style={{
                                  aspectRatio: 1/1,
                                  objectFit: 'cover'
                              }}
                              width="100%"
                              />
                              <CardModal key={nanoid()} title={item.img} url={item.img} />
                          </Card>
                      </Col>
                    ))}

                  </Slider>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </Col>

            
        
    );
  }
  
  export default ShowPhotos;
