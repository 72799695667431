import {
    Container,
    Row,
    Col,
    Button
} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import image1 from '../assets/img/photos/soho-marc.jpg'
import wereaband from '../assets/img/wereaband.png';
import { useState, useEffect } from "react";
import useCurrentWidth from "../components/width";

  function About() {

    

    let currentWidth = useCurrentWidth();

    function checkWidth(){
        if (currentWidth < 768){
            setIsSmall(true);
        }else{
            setIsSmall(false);
        }
        return(currentWidth);
      }

    const [isSmall, setIsSmall] = useState(false);

    useEffect(() => {
        checkWidth();
     });

    return (
        
        <div style={{height: "auto"}} className="mt-5 pt-2"> 
            <div id="about" className="floats"></div>
            <Container fluid style={{minHeight: '580px'}} className="about pt-4 mt-5">
                <Row className={isSmall ? "" : "hidden"}>
                    <Col className="d-flex justify-content-center align-items-center">
                        <img alt='We Are a Band' className="about-title" src={wereaband}></img>     
                    </Col>
                 </Row>
                <Row>
                    <Col className={isSmall ? "d-flex justify-content-center" : "d-flex justify-content-center mt-5"}>
                        <img alt='Soho Holiday at The Marc' className={isSmall ? "img-fluid mx-auto about-img" : "img-fluid mx-auto about-img mt-5"} src={image1}></img>
                    </Col>
                    <Col md={6} className={isSmall ? "" : "pt-5"}>
                        <Container className="">
                            <Row className={isSmall ? "hidden" : ""}>
                                <Col className=" mt-5">
                                    <img alt='We Are a Band' className="about-title" src={wereaband}></img>
                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col className="">
                                    <p className={isSmall ? "about-blurb" : "about-blurb pt-4"}>Soho Holiday is a modern indie-rock band from South Texas that first entered the scene in 2022. We have five musicians - Joe Powell, Hayden Denby, Hudson Devlin, Aidan Guzman, and Jonathan Andrew. We're influenced by classic funk, indie and new-age blues, and we always strive to bring classy and unique tunes to the party! You can stream our singles here:</p>
                   
                                </Col>
                            </Row>
                            <Row>
                                <Col className={isSmall ? "d-flex justify-content-center" : ""}>
                                <a href='https://linktr.ee/sohoholidaysm' target='about.blank'><Button className={isSmall ? "about-button mb-5 mr-2" : "mr-2 about-button mt-4"}><p className="my-auto">Our Links</p></Button></a>
                   
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>  
    );
  }
  
  export default About;
  