
  import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardSubtitle,
    CardTitle,
    CardText
  } from 'reactstrap';
  import 'bootstrap/dist/css/bootstrap.min.css';
  import { nanoid } from 'nanoid';
  import Slider from "react-slick";

  import { tourDates } from "./datafile";


  function ShowCards() {

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        responsive: [
                
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
              breakpoint: 500,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
        ],
      };


    return (
        
        <Col>
          <Container fluid >
            <Row>
              <Col>
                <Container fluid >
                  <Row>
                    <Col>
                      <Slider {...settings}>

                        {tourDates.map((item,index)=>(
                          <Col key={nanoid()} className="py-2 px-2 d-flex justify-content-center">
                              <Card
                                style={{
                                  width: '18rem'
                                }}
                              >
                                <img
                                  alt="Flyer"
                                  src={item.img}
                                />
                                <CardBody>
                                  <CardTitle tag="h5">
                                    {item.name} @ {item.venue}
                                  </CardTitle>
                                  <CardSubtitle
                                    className="mb-2 text-muted"
                                    tag="h6"
                                  >
                                    {item.date} <br></br> {item.location}
                                  </CardSubtitle>
                                  <CardText>
                                    {item.address}
                                  </CardText>
                                  {/* <Button href={item.tickets} target="about.blank">
                                    Tickets
                                  </Button> */}
                                </CardBody>
                              </Card>
                          </Col>
                        ))}
                      </Slider>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Col>

            
        
    );
  }
  
  export default ShowCards;
  