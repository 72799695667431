import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
  import {
    Container,
    Row,
    Col
  } from 'reactstrap';
  import 'bootstrap/dist/css/bootstrap.min.css';
  import useCurrentWidth from "../components/width";

  import soho from '../assets/img/logo.svg';
  import spotify from '../assets/img/spotify.svg';
  import applemusic from '../assets/img/applemusic.svg';
  import facebook from '../assets/img/facebook.png';
  import tiktok from '../assets/img/tiktok.png';
  import youtube from '../assets/img/youtube.png';
  import instagram from '../assets/img/instagram.png';


  function Footer() {
    let currentWidth = useCurrentWidth();

    function checkWidth(){
        if (currentWidth < 991){
            setIsSmall(true);
        }else{
            setIsSmall(false);
        }
        return(currentWidth);
      }

    const [isSmall, setIsSmall] = useState(false);

    useEffect(() => {
        checkWidth();
     });

     const handleClickScroll = (e) => {
        const element = document.getElementById(e);
        console.log(e);
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
    return (
        
        <div className="footer-background"> 
            <Container fluid className=" bubbles1">
                <Row className="d-flex justify-content-center h-100 ">
                <Col lg={4} className={isSmall ? "d-flex align-items-center mt-5" : "hidden"}>
                        <Container style={{maxWidth: '500px'}}>
                            <Row>
                                <Col className="d-flex justify-content-center">
                                    <h1 className="footer-map mx-auto">SITE MAP</h1>
                                </Col>
                            </Row>
                            <Row >
                                <Col  className="d-flex justify-content-around">
                                    <Link onClick={() => handleClickScroll('about')} className="footer-link">About</Link>
                                
                                    <Link onClick={() => handleClickScroll('music')} className="footer-link">Music</Link>
                                </Col>
                            </Row>
                            <Row >
                                <Col className="d-flex justify-content-between">
                                    <Link onClick={() => handleClickScroll('shows')} className="footer-link">Shows</Link>
                                
                                    <Link onClick={() => handleClickScroll('contact')} className="footer-link">Contact</Link>
                                </Col>
                            </Row>
                            <Row >
                                <Col className="d-flex justify-content-center">
                                    <Link onClick={() => handleClickScroll('photos')} className="footer-link">Photos</Link>
                                </Col>
                            </Row>
                        </Container>
                    
                    </Col>

                    <Col className={isSmall ? "d-flex justify-content-center h-100 " : "hidden"}>
                        <Container className="bubbles2 h-100 " lg={6}>
                            <Row className="h-100  ">
                                <Col className="bubbles-mobile d-flex align-items-center py-5">
                                     <div className="d-flex w-100 ">
                                        <img alt="Soho Holiday" className="footer-logo-mobile  " src={soho}></img>
                                    </div>   
                                    

                                </Col>
                            </Row>
                            <Row >
                                <Col  className="mobile-social-container d-flex justify-content-evenly ">
                                <div className=" ">
                                                        <a href="https://www.instagram.com/sohoholiday" target="about.blank"><img alt="Instagram" className="flex-logo-item" style={{height: 40}} src={instagram}></img></a>
                                                    </div> 
                                                    <div className=" ">
                                                        <a href="https://open.spotify.com/artist/5iJbuuhY356JbdMY8VSfan?si=yqS4mQKtQ_2CS97m2s4Fog" target="about.blank"><img alt="Spotify" className="flex-logo-item " style={{height: 40}} src={spotify}></img></a>
                                                    </div>
                                                    <div className=" ">
                                                        <a href="https://music.apple.com/us/artist/soho-holiday/1661745235" target="about.blank"><img alt="Apple Music" className="flex-logo-item " style={{height: 40}} src={applemusic}></img></a>
                                                    </div>
                                                    {/* <div >
                                                        <img alt="Facebook" className="flex-logo-item " style={{height: 40}} src={facebook}></img>
                                                    </div> */}
                                </Col>
                            </Row>
                        </Container>
                        
                    </Col>

                    <Col className={isSmall ? "hidden" : "d-flex justify-content-center bubbles2 h-100 w-100"}>
                        <Container className=" h-100 w-100" lg={6}>
                            <Row className="d-flex align-items-end h-100 w-100">
                                <Col  className="bubbles3 d-flex align-items-end ">
                                    
                                    
                                        <Container>
                                            <Row>
                                                <Col className="flex-item-end">
                                                    <div className="">
                                                        <img alt="Soho Holiday" className="footer-logo " src={soho}></img>
                                                    </div> 
                                                </Col>
                                            </Row>
                                            <Row style={{maxWidth: '500px'}} >
                                                <Col  className="social-container d-flex justify-content-evenly">
                                                    <div className=" ">
                                                        <a href="https://www.instagram.com/sohoholiday" target="about.blank"><img alt="Instagram" className="flex-logo-item" style={{height: 40}} src={instagram}></img></a>
                                                    </div> 
                                                    <div className=" ">
                                                        <a href="https://open.spotify.com/artist/5iJbuuhY356JbdMY8VSfan?si=yqS4mQKtQ_2CS97m2s4Fog" target="about.blank"><img alt="Spotify" className="flex-logo-item " style={{height: 40}} src={spotify}></img></a>
                                                    </div>
                                                    <div className=" ">
                                                        <a href="https://music.apple.com/us/artist/soho-holiday/1661745235" target="about.blank"><img alt="Apple Music" className="flex-logo-item " style={{height: 40}} src={applemusic}></img></a>
                                                    </div>
                                                    {/* <div >
                                                        <img alt="Facebook" className="flex-logo-item " style={{height: 40}} src={facebook}></img>
                                                    </div> */}
                                                </Col>
                                            </Row>
                                        </Container>
                                    
                                    
                                </Col>
                            </Row>
                            
                        </Container>
                        
                    </Col>
                    <Col lg={4} className={isSmall ? "hidden" : "d-flex align-items-center"}>
                        <Container>
                            <Row>
                                <Col className="d-flex justify-content-center">
                                    <h1 className="footer-map mx-auto">SITE MAP</h1>
                                </Col>
                            </Row>
                            <Row >
                                <Col  className="d-flex justify-content-around">
                                    <Link onClick={() => handleClickScroll('about')} className="footer-link">About</Link>
                                
                                    <Link onClick={() => handleClickScroll('music')} className="footer-link">Music</Link>
                                </Col>
                            </Row>
                            <Row >
                                <Col className="d-flex justify-content-between">
                                    <Link onClick={() => handleClickScroll('shows')} className="footer-link">Shows</Link>
                                
                                    <Link onClick={() => handleClickScroll('photos')} className="footer-link">Photos</Link>
                                
                                    <Link onClick={() => handleClickScroll('contact')} className="footer-link">Contact</Link>
                                </Col>
                            </Row>
                        </Container>
                    
                    </Col>
                    <Col className={isSmall ? "hidden" : ""} lg={2}>
                    </Col>
                </Row>
            </Container> 
        </div>  
    );
  }
  
  export default Footer;
  