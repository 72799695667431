
  import {
    Container,
    Row,
    Col
  } from 'reactstrap';
  import 'bootstrap/dist/css/bootstrap.min.css';

  import ShowCards from '../components/showcards.js';

  import shows from '../assets/img/upcomingshows.png';


  function Shows() {
    return (
        
        <div className=""> 
            <div id="shows" className="floats"></div>
            <Container fluid className="shows pb-5">
                <Row className="d-flex justify-content-center">
                    <Col className="d-flex justify-content-center">
                        <img alt='Soho Show' className="img-fluid" src={shows}></img>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center">
                    <ShowCards />
                </Row>
            </Container> 
        </div>  
    );
  }
  
  export default Shows;
  