import { useState } from "react";
  import {
    Button,
    CardImgOverlay,
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter
  } from 'reactstrap';
  import { nanoid } from "nanoid";
  import 'bootstrap/dist/css/bootstrap.min.css';

  
  function CardModal({title, url}) {
    const [modal, setModal] = useState(false);


    const toggle = () => setModal(!modal);

    return (
        
        <div key={nanoid()}>  

        <CardImgOverlay className="overlay-container" onClick={toggle}>
            <div className="overlay">
                
            </div>
            <Modal size='lg' centered isOpen={modal} toggle={toggle}>
                
                <ModalHeader toggle={toggle}></ModalHeader>
                <ModalBody className="d-flex justify-content-center">
                    <img alt={title} className="img-fluid" src={url}></img>
                </ModalBody>
                <ModalFooter>
                
                <Button color="danger" outline onClick={toggle}>
                    Close
                </Button>
                </ModalFooter>
            </Modal>
        </CardImgOverlay>

            

        </div>  
    );
  }
  
  export default CardModal;
  