
  import {
    Container,
    Row,
    Col
  } from 'reactstrap';
  import 'bootstrap/dist/css/bootstrap.min.css';

  import ShowPhotos from '../components/showphotos.js';

  import photo from '../assets/img/photos.png';


  function Photos() {
    return (
        
        <div className="" style={{backgroundColor: "black"}}> 
            <div id="photos" className="floats"></div>
            <Container fluid className="">
                <Row className="d-flex justify-content-center">
                    <Col className="d-flex justify-content-center">
                        <img alt="Soho Holiday" className="img-fluid" src={photo}></img>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center">
                    <ShowPhotos />
                </Row>
            </Container> 
        </div>  
    );
  }
  
  export default Photos;
  