import './App.css';
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import Home from './pages/home.js';
import Header from './components/header.js';
import Footer from './components/footer.js';


function App() {
  return (
    <Router>
        <Header />
        <Routes>
          <Route exact path='/' element={<Home/>} />
        </Routes>
        <Footer />
    </Router>
  );
}

export default App;
