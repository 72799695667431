import { useState, useEffect } from "react";

  import {
    Container,
    Row,
    Col
  } from 'reactstrap';
  import 'bootstrap/dist/css/bootstrap.min.css';
//   import ContactForm from '../components/contactform.js';
  import useCurrentWidth from "../components/width";

  import contactus from '../assets/img/contactus.png';
  import ourinfo from '../assets/img/ourinfo.png';


  function Contact() {
    let currentWidth = useCurrentWidth();

    function checkWidth(){
        if (currentWidth < 1027){
            setIsSmall(true);
        }else{
            setIsSmall(false);
        }
        return(currentWidth);
      }

    const [isSmall, setIsSmall] = useState(false);

    useEffect(() => {
        checkWidth();
     });
    return (
        
        <div className="contact"> 
            <div id="contact" className="floats"></div>
            <Container fluid className="h-100">
                <Row className="d-flex justify-content-center h-100">
                    <Col lg={12} className={isSmall ? "d-flex justify-content-center align-items-center h-100 mt-4" : "hidden" }>
                            <Row>
                                <Col  className="d-flex justify-content-center">
                                    <img alt="Contact Us" className="img-fluid mb-4 contact-us mx-auto" src={contactus}></img>
                                </Col>
                            </Row>
                    </Col>
                    <Col lg={12} className={isSmall ? "mb-4 d-flex justify-content-center align-items-center h-100" : "hidden" }>
                        <div className="contact-card-1 pb-4">
                                <Row>
                                    <Col className="d-flex justify-content-center align-items-center">
                                        <img alt="Our Info" src={ourinfo}></img>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="">
                                        <Container>
                                            <Row>
                                                <Col className="d-flex mx-3">
                                                    <h3>Email: </h3> <h3 className="text-black word-break px-4">booking@soho-holiday.com</h3>
                                                </Col>
                                            </Row>
                                            {/* <Row>
                                                <Col className="d-flex mx-3">
                                                    <h3>Phone</h3> <h3 className="text-black px-4">###-###-####</h3>
                                                </Col>
                                            </Row> */}
                                            <Row>
                                                <Col className="d-flex mx-3">
                                                    <h3>Location: </h3> <h3 className="text-black px-4">San Marcos/New Braunfels, TX</h3>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="d-flex mx-3">
                                                    <h3>Instagram: </h3> <h3 className="text-black px-4">@sohoholiday</h3>
                                                </Col>
                                            </Row>
                                            
                                        </Container>
                                    </Col>
                                </Row>
                            </div>
                    </Col>

                    {/* <Col lg={12} className={isSmall ? "d-flex justify-content-center align-items-center h-100 mt-4 mb-5" : "hidden" }>
                            <Row className="d-flex justify-content-center w-100">
                                <Col className=" contact-card-2 w-100">
                                    <ContactForm />
                                </Col>
                            </Row>
                    </Col> */}

                    {/* <Col className={isSmall ? "hidden" : "d-flex justify-content-center align-items-center"}>
                        <Container className="d-flex justify-content-center align-items-center">
                            <div className="contact-card-1 pb-4">
                                <Row>
                                    <Col className="d-flex justify-content-center align-items-center">
                                        <img src={ourinfo}></img>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="">
                                        <Container>
                                            <Row>
                                                <Col className="d-flex mx-3">
                                                    <h3>Email:</h3> <h3 className="text-black word-break pl-4">info.sohoholiday@gmail.com</h3>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="d-flex mx-3">
                                                    <h3>Phone</h3> <h3 className="text-black px-4">###-###-####</h3>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="d-flex mx-3">
                                                    <h3>Location: </h3> <h3 className="text-black px-4">San Marcos/New Braunfels, TX</h3>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="d-flex mx-3">
                                                    <h3>Instagram: </h3> <h3 className="text-black px-4">@sohoholiday</h3>
                                                </Col>
                                            </Row>
                                            
                                        </Container>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </Col> */}

                    <Col className={isSmall ? 'hidden' : "d-flex justify-content-center align-items-center h-100 px-3" }>
                        <Container className="py-5 ">
                            <Row>
                                <Col  className="d-flex justify-content-center">
                                    <img alt="Contact Us" className="img-fluid mb-4 contact-us mx-auto" src={contactus}></img>
                                </Col>
                            </Row>
                            <div className="contact-card-1 mb-4">
                                <Row>
                                    <Col className="d-flex justify-content-center align-items-center">
                                        <img alt="Our Info" src={ourinfo}></img>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col >
                                        <Container className="">
                                            <Row>
                                                <Col className="d-flex mx-3">
                                                    <h3>Email: </h3> <h3 className="text-black word-break pl-4">info.sohoholiday@gmail.com</h3>
                                                </Col>
                                            </Row>
                                            {/* <Row>
                                                <Col className="d-flex mx-3">
                                                    <h3>Phone</h3> <h3 className="text-black px-4">###-###-####</h3>
                                                </Col>
                                            </Row> */}
                                            <Row>
                                                <Col className="d-flex mx-3">
                                                    <h3>Location: </h3> <h3 className="text-black px-4">San Marcos/New Braunfels, TX</h3>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="d-flex mx-3">
                                                    <h3>Instagram: </h3> <h3 className="text-black px-4">@sohoholiday</h3>
                                                </Col>
                                            </Row>
                                            
                                        </Container>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </Col>


                </Row>
            </Container> 
        </div>  
    );
  }
  
  export default Contact;
  