
import themarc1 from "../assets/img/photos/soho-marc.jpg";
import trailheadjoe from '../assets/img/photos/trailheadjoe.png';
import trailheaddavidjoe from '../assets/img/photos/trailheaddavidjoe.png';
import trailheaddavidjoe2 from '../assets/img/photos/trailheaddavidjoe2.png';
import trailheaddavidjon from '../assets/img/photos/trailheaddavidjon.png';
import trailheadhudson from '../assets/img/photos/trailheadhudson.png';
import trailheadhudson2 from '../assets/img/photos/trailheadhudson2.png';
import trailheadjoe2 from '../assets/img/photos/trailheadjoe2.png';
import trailheadjon from '../assets/img/photos/trailheadjon.png';
import img1 from '../assets/img/photos/1.jpeg';
import img2 from '../assets/img/photos/2.png';
import img3 from '../assets/img/photos/3.png';
import img4 from '../assets/img/photos/4.png';
import img5 from '../assets/img/photos/5.jpg';
import img6 from '../assets/img/photos/6.jpg';
import img7 from '../assets/img/photos/7.jpg';
import img8 from '../assets/img/photos/8.jpeg';
import img9 from '../assets/img/photos/9.jpeg';
import img10 from '../assets/img/photos/10.jpeg';
import img11 from '../assets/img/photos/11.jpeg';
import img12 from '../assets/img/photos/12.jpeg';
import img13 from '../assets/img/photos/13.jpeg';
import img14 from '../assets/img/photos/14.jpeg';
import img15 from '../assets/img/photos/15.jpeg';
import img16 from '../assets/img/photos/16.jpeg';
import img17 from '../assets/img/photos/17.jpeg';
import img18 from '../assets/img/photos/18.jpeg';
import img19 from '../assets/img/photos/19.jpeg';
import img21 from '../assets/img/photos/21.jpeg';
import img22 from '../assets/img/photos/22.jpg';

import frightsandsounds from '../assets/img/flyers/frightsandsounds.jpg';
import tba from '../assets/img/flyers/tba.png';
import carousel from '../assets/img/flyers/carousel.png';
import tandem from '../assets/img/flyers/tandem.png';

import makeup from "../assets/img/makeup.jpg";
import choppyone from "../assets/img/choppyone.jpg";

import logo from '../assets/img/flyers/logo-black-bg.png';

const tourDates = [
  {date: "Sunday, February 11, 2024", name: "D'foy", venue: "The Far Out Lounge", location: "Austin, TX", tickets: "", address: "8504 S Congress Ave, Austin TX", img: logo},
  
  {date: "Thursday, February 28th, 2024", name: "Soho Holiday", venue: "Carousel Lounge", location: "Austin, TX", tickets: "", address: "1110 E 52nd St, Austin, TX", img: carousel},

  {date: "Saturaday, March 2nd, 2024", name: "Soho Holiday", venue: "The Porch", location: "San Marcos, TX", tickets: "", address: "129 E Hopkins St. #110, San Marcos, TX", img: logo},

  {date: "Saturady, April 20th, 2024", name: "Soho Holiday", venue: "Tandem Coffee", location: "San Antonio, TX", tickets: "", address: "310 Riverside Dr, San Antonio, TX", img: tandem},
];

const cards = [
  {date: "Sat, jun 25", name: "event name", venue: "house of Rock test", location: "san marcos, TX", tickets: "href", address: "addy",},
  {date: "Sat, jun 25", name: "event name", venue: "house of Rock", location: "san marcos, TX", tickets: "#", address: "addy",},
];

const photo = [
  
  {img: img1,},
  {img: img2,},
  {img: img3,},
  {img: img4,},
  {img: img5,},
  {img: img6,},
  {img: img7,},
  {img: img8,},
  {img: img9},
  {img: img10},
  {img: img11},
  {img: img12},
  {img: img13},
  {img: img14},
  {img: img15},
  {img: img16},
  {img: img17},
  {img: img18},
  {img: img19},
  {img: img21},
  {img: img22},
  {img: themarc1,},
  {img: trailheadjoe,},
  {img: trailheaddavidjoe,},
  {img: trailheaddavidjoe2,},
  {img: trailheadhudson,},
  {img: trailheadhudson2,},
  {img: trailheadjon,},
  {img: trailheadjoe2,},
  {img: trailheaddavidjon,},
  



];

const releases = [
  {img: makeup, title: "Make Up", artist: "Soho Holiday", hyperfollow: "https://distrokid.com/hyperfollow/sohoholiday/make-up"},
  {img: choppyone, title: "The Choppy One", artist: "Soho Holiday", hyperfollow: "https://distrokid.com/hyperfollow/sohoholiday/the-choppy-one"},



];


export {
  tourDates,
  cards,
  photo,
  releases
};
