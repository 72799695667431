import { useState, useEffect } from "react";

  import {
    Container,
    Row,
    Col
  } from 'reactstrap';
  import MusicPhotos from "../components/musicphotos.js"
  import 'bootstrap/dist/css/bootstrap.min.css';
  import useCurrentWidth from "../components/width";
  import music from '../assets/img/music.png';


  function Music() {
    let currentWidth = useCurrentWidth();

    function checkWidth(){
        if (currentWidth < 991){
            setIsSmall(true);
        }else{
            setIsSmall(false);
        }
        return(currentWidth);
      }

    const [isSmall, setIsSmall] = useState(false);

    useEffect(() => {
        checkWidth();
     });
    return (
        
        <div id="" className="music-container"> 
            <div id="music" className="floats"></div>
            <Container fluid className="music">
                <Row className="align-items-center h-100">
                    <Col>
                        <Container>
                            <Row>
                                <Col lg={6}>
                                    <Container className="mx-auto">
                                        <Row>
                                            <Col className={isSmall ? "d-flex align-items-center  justify-content-center h-100" : "d-flex align-items-center h-100"}>
                                                 <img alt="Soho Holiday New Release" className={isSmall ? "music-text img-fluid  mx-auto my-5 pt-3 align-items-center" : "music-text img-fluid mt-5 pt-3 "} src={music}></img> 


                                            
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="d-flex align-items-center h-100">
                                                <p className="about-blurb pt-4">We have a few singles released on all platforms you can listen to, Make Up and The Choppy One. More to come! </p>

                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                                <Col lg={6}>
                                    <Container>
                                        <Row>
                                            <Col className={isSmall ? "d-flex justify-content-center" : "d-flex justify-content-center"}>
                                                {/* <img className={isSmall ? "img-fluid mx-auto about-img" : "img-fluid mx-auto about-img mt-5"} src={image1}></img> */}
                                                <MusicPhotos />


                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container> 
        </div>  
    );
  }
  
  export default Music;
  