
  import {
    Container,
    Row,
    Col,
    Card,
    CardImg
  } from 'reactstrap';
  import 'bootstrap/dist/css/bootstrap.min.css';
  import { nanoid } from 'nanoid';
  import Slider from "react-slick";
  import { releases } from './datafile.js';



  function MusicPhotos() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 900,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
      };


    return (
        
    
      <Container>
        <Row className="pb-5">
          <Col>
            <Container fluid>
              <Row>
                <Col>
                  <Slider {...settings}>

                    {releases.map((item,index)=>(
                      <Col key={nanoid()} className="py-2 px-2 mx-auto">
                          <Card inverse className='mx-auto' style={{
                                  aspectRatio: 1/1,
                                  maxWidth: "80%"
                              }}>
                              
                              <a href={item.hyperfollow} target="about.blank">
                                <CardImg
                                alt="Card img"
                                src={item.img}
                                style={{
                                    aspectRatio: 1/1,
                                    objectFit: 'cover'
                                }}
                                width="80%"
                                />
                              </a>
                          </Card>
                              
                          <a href={item.hyperfollow} target="about.blank"><h4 className="text-center mt-3">{item.title}</h4></a>
                          <a href={item.hyperfollow} target="about.blank"><h5 className="text-center text-white">{item.artist}</h5></a>
                      </Col>
                    ))}

                  </Slider>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    

            
        
    );
  }
  
  export default MusicPhotos;
