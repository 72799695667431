
  import 'bootstrap/dist/css/bootstrap.min.css';

  import About from '../elements/about.js';
  import Music from '../elements/music.js';
  import Shows from '../elements/shows.js';
  import Contact from '../elements/contact.js';
  import Photos from '../elements/photos.js';
  
  function Home() {
    return (
        
        <div>  

            <About />
            <Photos />
            <Music />
            <Shows />
            <Contact />
            
           
        </div>  
    );
  }
  
  export default Home;
  