import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import useCurrentWidth from "../components/width";
import Logo from '../assets/img/logo.svg';


function Header() {

   

    let currentWidth = useCurrentWidth();

    function checkWidth(){
        if (currentWidth < 1091){
            setIsSmall(true);
        }else{
            setIsSmall(false);
        }
        return(currentWidth);
      }

    const [isOpen, setIsOpen] = useState(false);

    const [isSmall, setIsSmall] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        checkWidth();
     });

   
     const handleClickScroll = (e) => {
        const element = document.getElementById(e);
        console.log(e);
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
    


  return (

    <Navbar
    className="header-color d-flex justify-content-evenly px-2"
    light
    fixed="top"
    >
        
    <NavbarBrand onClick={() => handleClickScroll('about')} className={isSmall ? "nav-brand px-2" : "nav-brand px-2 mx-auto"} >
      <img
        className={isSmall ? "logo-small" : "logo"}
        alt="logo"
        src={Logo}
      />
    </NavbarBrand>
    
    

    <Nav className={isSmall ? "hidden" : "nav1 mx-auto"} >
        <NavItem>
           <Link onClick={() => handleClickScroll('music')} className="nav1 nav-link" >Music</Link>
        </NavItem>
         <NavItem>
           <a target="about_blank" href="https://mailordermedia.com/sohoholiday" className="nav1 nav-link" >Merch</a>
        </NavItem>
        
    </Nav>

    
    <Nav className={isSmall ? "hidden" : "mid-pad px-2 mx-auto"} >
    </Nav>
    

    <Nav className={isSmall ? "hidden" : "nav2 mx-auto"} >
        <NavItem>
           <Link onClick={() => handleClickScroll('shows')} className="nav2 nav-link" >Shows</Link>
        </NavItem>
         <NavItem>
           <Link onClick={() => handleClickScroll('photos')} className="nav2 nav-link" >Photos</Link>
        </NavItem>
        <NavItem>
           <Link onClick={() => handleClickScroll('contact')} className="nav2 nav-link" >Contact</Link>
        </NavItem>
    </Nav>

    <Nav className={isSmall ? "" : "nav-brand px-3 mx-auto"} >
        <NavbarToggler className={isSmall ? "" : "hidden"} onClick={toggle} />
    </Nav>

    <Collapse isOpen={isOpen} navbar className={isSmall ? "mt-5" : "hidden"}>
        <Nav className="d-flex justify-content-center">
            <NavItem >
                <Link onClick={() => handleClickScroll('about')} className="nav1" >About</Link>
            </NavItem>
        </Nav>
        <Nav className="d-flex justify-content-center">
            <NavItem>
                <Link onClick={() => handleClickScroll('music')} className="nav1" >Music</Link>
            </NavItem>
        </Nav>
        <Nav className="d-flex justify-content-center">
            <NavItem>
                <Link onClick={() => handleClickScroll('shows')} className="nav2" >Shows</Link>
            </NavItem>
        </Nav>
        <Nav className="d-flex justify-content-center">
            <NavItem>
                <Link onClick={() => handleClickScroll('photos')} className="nav2" >Photos</Link>
            </NavItem>
        </Nav>
        <Nav className="d-flex justify-content-center">
            <NavItem>
                <Link onClick={() => handleClickScroll('contact')} className="nav2" >Contact</Link>
            </NavItem>
        </Nav>
    </Collapse>

   

  </Navbar>
    
  );
}

export default Header;
